import acpPanCvcBlockCtrl from './acp-pan-cvc-block-ctrl';
import acpPanCvcBlockDirective from './acp-pan-cvc-block-directive';
import acpComponentPanCvcCheck from 'components/pan-cvc-check';
import acpCore from 'core';
import ng from 'angular';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.pan-cvc-block
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-pan-cvc-block></acp-pan-cvc-block>
 * ```
 *
 * ```js
 * angular.module('acp.component.pan-cvc-block.example', [
 *   require('acp.component.pan-cvc-block').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.pan-cvc-block', [
    acpCore.name,
    acpComponentPanCvcCheck.name
  ])
  .directive('acpPanCvcBlock', acpPanCvcBlockDirective)
  .controller('acpPanCvcBlockComponentCtrl', acpPanCvcBlockCtrl);

export default component;
