import acpPanCvcBlockTemplate from './templates/acp-pan-cvc-block.html';
// @ngInject
function acpPanCvcBlockDirective() {
  return {
    controller: 'acpPanCvcBlockComponentCtrl',
    restrict: 'E',
    scope: {
      buttonAriaLabel: '@',
      buttonLabel: '@'
    },
    template: acpPanCvcBlockTemplate
  };
}

export default acpPanCvcBlockDirective;
