// @ngInject
function acpPanCvcBlockComponentCtrl(
  $scope,
  acpCoreDispatcher,
  acpCvcAuthenticationModel
) {
  function init() {
    $scope.$onValue(
      acpCoreDispatcher.authenticate.cvcPanAuthFormValidity,
      onCvcValidityChanged
    );
    $scope.cvcAuthParams = {};
    $scope.cvcValid = false;
    $scope.submittingCvc = false;
  }

  function onCvcValidityChanged(valid) {
    $scope.cvcValid = valid;
  }

  function submitCvcFinally() {
    $scope.submittingCvc = false;
  }

  $scope.submitCvc = function() {
    if (!$scope.cvcValid) {
      return;
    }
    acpCoreDispatcher.scrollTo.top.emit();
    $scope.submittingCvc = true;
    $scope.cvcAuthParams.finally = submitCvcFinally;
    acpCvcAuthenticationModel.authenticate($scope.cvcAuthParams);
  };

  init();
}

export default acpPanCvcBlockComponentCtrl;
